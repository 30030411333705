import { Link } from "react-router-dom";
import BgShape from "../images/hero/hero-bg1.jpg";
import BgShape1 from "../images/hero/hero-bg2.jpg";
import HeroCar from "../images/hero/main-car.png";
import { useEffect, useState } from "react";
import whatsapp from "../images/logo/whatsapp.png";
import facebook from "../images/logo/fb.png";
import insta from "../images/logo/insta.png";
import './shining.css'; // Ensure this is your CSS file path


function Hero() {
  const [goUp, setGoUp] = useState(false);
  //const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const bookBtn = () => {
    document.querySelector('#booking-section').scrollIntoView({ behavior: 'smooth' });
  };

  const bookBtn2 = () => {
    document.querySelector('#options').scrollIntoView({ behavior: 'smooth' });
  };

  const openWhatsApp = () => {
    window.open('https://wa.me/18768395716', '_blank');   
  };

  const openfacebook = () => {
    window.open('https://www.facebook.com/profile.php?id=61564205586638/', '_blank'); 
   };

   const openinsta = () => {
    window.open('https://www.instagram.com/sleek_experience?igsh=dnlnbmZwOXU2MjJz&utm_source=qr', '_blank'); 
   };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', onPageScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', onPageScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const styles = {
    btn: {
     // background: 'linear-gradient(135deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)',
    //backgroundSize: '200% 200%',
   // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
   backgroundColor:'transparent',
   // padding: '10px',
    borderRadius: '10px',
    color: 'white',
    textAlign: 'center',
    //animation: 'glow 1.5s infinite alternate, shine 1s infinite linear',
    animation: 'glow 3s infinite',
      transition: 'box-shadow 0.3s ease-in-out',
      fontSize: '17px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px 15px',
      margin: '5px', // add margin for spacing between buttons
      borderColor:'gold',
      borderRadius: '8px', color:'white'
    },
  };


  const [slideIn, setSlideIn] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Trigger the slide-in animation after a short delay
    const delay = setTimeout(() => {
      setSlideIn(true);
    }, 850); // Adjust the delay as needed

    // Clear the timeout on component unmount to avoid memory leaks
    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slideAnimation = {
    zIndex: 2,
    position: 'absolute',
    right: slideIn ? 40 : '-100%',
    transition: 'right 2.0s ease-in-out',
    width: '100%', // Set the maximum width to 100%
    height: 'auto', // Maintain the aspect ratio
    maxWidth: windowWidth < 768 ? '80%' : '55%', // Set maxWidth for mobile view
    marginTop: '5rem',
  };
  



  const containerStyle = {
    position: 'relative', height:'730px', marginTop:'-45px'
    //backgroundImage: 'linear-gradient(315deg, #00690a 0%, #3a9e0e 74%)',
  };

  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  const textStyle = {
    animation: 'fadeIn 3s ease-in-out, glow 1.5s infinite alternate',
  };

  const [imageSrc, setImageSrc] = useState(BgShape);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImageSrc(BgShape1);
      } else {
        setImageSrc(BgShape);
      }
    };

    handleResize(); // Check on mount
    window.addEventListener('resize', handleResize); // Add listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up listener on unmount
    };
  }, []);


  return (
    <section id="home" className="banner-section" style={containerStyle}>
      <div className="container" >
      <img src={imageSrc} alt="Background Shape" style={bgImageStyle} />
      <div className="hero-content" style={{ position: 'relative', zIndex: 2, height:'350px', marginTop:'14.5rem' }}>
        <div className="hero-content__text">
          

          <h1 style ={{ marginTop:'39px',   animation: 'glowText 1.5s infinite alternate, bounce 2s  ease-in-out'}}>
      Sleek Experience
          </h1>
          <div style={{ height: '110px', width:'90%' }}>
            {windowWidth >= 2100 ? (
              <div style={styles.container}>
                <p style={{ color:'black' ,backgroundColor: 'rgba(255, 255, 255, 0.6)', borderRadius:'45px'}}>
                Experience the car of your dreams with Road Rent A Car. Enjoy unparalleled prices, unlimited mileage.
                </p>
              </div>
            ) : null}
          </div>
          <div className="hero-content__text__btns">
            <Link onClick={bookBtn} className="hero-content__text__btns__book-ride" to="/" style={styles.btn}>
              Book Car
            </Link>

            <Link onClick={bookBtn2} className="hero-content__text__btns__book-ride" to="/" style={styles.btn}>
            Tours/Transfers
            </Link>

            <Link className="hero-content__text__btns__book-ride" to="/contact" style={styles.btn}>
              Contact Us <i className="fa-solid fa-angle-right"></i>
            </Link>
          </div>
        </div>
        {/* 
        <img src={HeroCar} alt="car-img" style={slideAnimation} /> */}
      </div>
    </div>

      {/* WhatsApp button */}

      <div
        onClick={openWhatsApp}
        className="whatsapp-btn"
        style={{
          position: 'fixed',
          bottom: '185px',
          left: '1rem',
          zIndex: '14', 
        }}
      >
        <img src={whatsapp} alt="Facebook-img" style={{ height: 55, width: 55, borderRadius:'7px' }} />
      </div>

      <div
        onClick={openinsta}
        className="whatsapp-btn"
        style={{
          position: 'fixed',
          bottom: '105px',
          left: '1rem',
          zIndex: '14', 
        }}
      >
        <img src={insta} alt="Facebook-img" style={{ height: 55, width: 55, borderRadius:'7px' }} />
      </div>



      <div
        onClick={openfacebook}
        className="whatsapp-btn"
        style={{
          position: 'fixed',
          bottom: '30px',
          left: '1rem',
          zIndex: '14', 
        }}
      >
        <img src={facebook} alt="Facebook-img" style={{ height: 55, width: 55, borderRadius:'7px' }} />
      </div>

      {/* Scroll to top button */}
      <div style={{   background: 'linear-gradient(325deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)',
          backgroundSize: '200% 200%',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',}} onClick={scrollToTop} className={`scroll-up ${goUp ? 'show-scroll' : ''}`}>
        <p style={{ fontSize: 22 }}>&#9650;</p>
      </div>
    </section>
  );
}

export default Hero;

const styles = {
  container: {
   // height: '110px',
    display: 'block', // Ensure it's a block element
   
  },
  // Media query to hide the container when the screen width is less than 500px
  '@media (max-width: 800px)': {
    container: {
      display: 'none',
      
    },
  },
};