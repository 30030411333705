
function Footer() {
  function handleSubscribe() {
    alert("Thanks for subscribing!!!");
  }

  return (
    <>
      <footer style={{height:'120px', marginTop:'-90px'}}>
        <div className="container" >
          <div className="footer-content">
            <ul className="footer-content__2">
              <li>Contact Us</li>

              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a href="tel:+1 (876)798-7720">&#x260E; +1 (876)798-7720</a>
              </li>

              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a href="tel:+1 (876) 839-5716">&#x260E; +1 (876) 839-5716</a>
              </li>

              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a
                  href="mailto: 
                info@sleekexperience.com"
                >
                  &#9993; info@sleekexperience.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                  target="_blank"
                  rel="noreferrer"
                 // href="https://maps.app.goo.gl/gyo3oLjVkVEJomSi8"
                  // href="https://jephunneh.com/"
                >
                  &#128205; 5 Windsor Rd, St Ann's Bay, Jamaica
                </a>
              </li>


            </ul>

            <ul className="footer-content__1">
              <li>
                <span style={{ color: "#B8860B" }}>Sleek Investments Car Rental</span>
               
              </li>

              <li style={{ fontSize: "13px" }}>
                We're the best choice if you are looking to rent a car in Jamaica. We offer a range of vehicles for car rental with great customer support.
              
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev2code.com"
                >
                  <span>Website By:</span>
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://web4business.tech"
                >
                  <span style={{ color: "#B8860B" }}>Qasim</span>
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                {/*<a   href="https://maps.app.goo.gl/gyo3oLjVkVEJomSi8"> 
                  Our Location
                </a> */}
              </li>
              <li>
                <a href="/">Careers</a>
              </li>
              <li>
                <a href="/">Mobile</a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://adminsleekrentals.netlify.app/"
                  //
                >
                  Admin &#128187;
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sat: 8:00AM - 5:00PM</li>
              <li>Sun: 10:00am - 3:00 pm</li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
